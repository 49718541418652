import "babel-polyfill"
import "isomorphic-fetch"
import React from "react"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import { amplifyconfig } from "../utils/config"
import { Link } from "gatsby"

import { navigate } from "@reach/router"

import { FaCheck, FaTimes } from "react-icons/fa"

Amplify.configure(amplifyconfig)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      password2: "",
      confirmationcode: "",
      codeScreen: false,
      isFinished: false,
      inApp: false,
      loading: false,
      error: [],
    }
  }

  componentDidMount() {
    console.log("this.props.userobject")
    console.log(this.props.userobject)
    this.setState({
      email: this.props.userobject.challengeParam.userAttributes.email,
    })
  }

  resetForm = () => {
    this.setState({
      email: "",
      password: "",
      password2: "",
      confirmationcode: "",
      codeScreen: false,
      loading: false,
      error: [],
    })
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleSubmit = async () => {
    // const { username, email, phone_number } = getInfoFromUserInput()
    // const loggedUser = await Auth.completeNewPassword(
    //   this.props.userobject, // the Cognito User Object
    //   this.state.password, // the new password
    //   // OPTIONAL, the required attributes
    //   {
    //     email: this.state.email,
    //   }
    // )

    Auth.completeNewPassword(
      this.props.userobject, // the Cognito User Object
      this.state.password, // the new password
      // OPTIONAL, the required attributes
      {
        email: this.state.email,
      }
    )
      .then(user => {
        navigate("/app/home")
      })
      .catch(e => {
        console.log("((((((e))))))")
        console.log(e)
        this.setState({ error: e })
      })

    console.log("loggedUser")
    console.log(loggedUser)
  }

  render() {
    return (
      <form class="form-signin">
        {this.state.error.message ? (
          <div class="alert alert-danger text-center" role="alert">
            {this.state.error.message}
          </div>
        ) : (
          ""
        )}
        <h3 class="form-signin-heading text-center pb-2">Change Password</h3>

        {this.state.email ? (
          <div>
            <p class="password-instructions text-center">
              Before getting started, please change your password.
            </p>

            {/* <label for="input-email" class="sr-only">
              Confirmation code
            </label>

            <input
              type="text"
              id="input-confirmationcode"
              name="confirmationcode"
              class="form-control mb-4"
              placeholder="Enter confirmation code"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            /> */}

            <input
              type="password"
              id="input-password"
              name="password"
              class="form-control mb-2"
              placeholder="Enter new password"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />
            <input
              type="password"
              id="input-password2"
              name="password2"
              class="form-control mb-2"
              placeholder="Confirm password"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />

            <div style={{ minHeight: 35 }}>
              {this.state.password ? (
                <div>
                  <div class="password-message text-right">
                    {this.state.password === this.state.password2 ? (
                      <div class="d-flex flex-row align-items-center justify-content-center green-1">
                        <FaCheck size={11} />{" "}
                        <div class="ml-1">Passwords match</div>
                      </div>
                    ) : (
                      <div class="d-flex flex-row align-items-center justify-content-center red-1">
                        <FaTimes size={11} />{" "}
                        <div class="ml-1">Passwords don't match</div>
                      </div>
                    )}
                  </div>
                  <div class="password-attributes-message text-right">
                    {this.state.password.length >= 8 ? (
                      <div class="d-flex flex-row align-items-center justify-content-center green-1">
                        <FaCheck size={11} />{" "}
                        <div class="ml-1">Password is 8+ characters</div>
                      </div>
                    ) : (
                      <div class="d-flex flex-row align-items-center justify-content-center red-1">
                        <FaTimes size={11} />{" "}
                        <div class="ml-1">Password must be 8+ characters</div>
                      </div>
                    )}
                  </div>
                  <div class="password-attributes-message text-right">
                    {/[a-z]/.test(this.state.password) ? (
                      <div class="d-flex flex-row align-items-center justify-content-center green-1">
                        <FaCheck size={11} />{" "}
                        <div class="ml-1">
                          Password contains one lowercase character
                        </div>
                      </div>
                    ) : (
                      <div class="d-flex flex-row align-items-center justify-content-center red-1">
                        <FaTimes size={11} />{" "}
                        <div class="ml-1">
                          Password must contains one lowercase character
                        </div>
                      </div>
                    )}
                  </div>
                  <div class="password-attributes-message text-right">
                    {/[A-Z]/.test(this.state.password) ? (
                      <div class="d-flex flex-row align-items-center justify-content-center green-1">
                        <FaCheck size={11} />{" "}
                        <div class="ml-1">
                          Password contains one uppercase character
                        </div>
                      </div>
                    ) : (
                      <div class="d-flex flex-row align-items-center justify-content-center red-1">
                        <FaTimes size={11} />{" "}
                        <div class="ml-1">
                          Password must contains one uppercase character
                        </div>
                      </div>
                    )}
                  </div>
                  <div class="password-attributes-message text-right">
                    {/[0-9]/.test(this.state.password) ? (
                      <div class="d-flex flex-row align-items-center justify-content-center green-1">
                        <FaCheck size={11} />{" "}
                        <div class="ml-1">Password contains one number</div>
                      </div>
                    ) : (
                      <div class="d-flex flex-row align-items-center justify-content-center red-1">
                        <FaTimes size={11} />{" "}
                        <div class="ml-1">
                          Password must contains one number
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>

            <input
              type="hidden"
              value="{{#if_eq layout 'minimal'}}1{{else}}0{{/if_eq}}"
              name="isapp"
            />

            <div class="password-attributes-message text-right">
              {this.state.password === this.state.password2 &&
              this.state.password.length >= 6 ? (
                <button
                  type="button"
                  class="btn btn-primary btn-block mt-2 app-pw-reset-submit"
                  onClick={this.handleSubmit}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  class="btn btn-primary btn-block mt-2 app-pw-reset-submit app-pw-reset-disabled"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        ) : (
          <p class="password-instructions text-center">
            Oops, we weren't able to get your email address. Please{" "}
            <Link to="/app/password" onClick={this.resetForm}>
              begin again.
            </Link>
          </p>
        )}
      </form>
    )
  }
}
export default App
